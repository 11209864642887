<template>
  <div v-if="false">
    {{ user }}
  </div>
</template>
<script>
import { mapState } from "vuex";
import { fetchThirdPartyMemberInfo } from "@/apis/fetchData";
export default {
  props: {},
  data() {
    return {
      // appId: process.env.VUE_APP_ID,
      ssoSDK: process.env.VUE_APP_SSO_SDK,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      orderInfo: (state) => state.orderInfo,
      ssoInitialized: (state) => state.ssoInitialized,
      ssoAppId: (state) => state.ssoAppId,
      isLoggedIn: (state) => state.isLoggedIn,
    }),
    thirdPartyMember() {
      return this.orderInfo?.member;
      // return {
      //   thirdPartyMemberId: 'U74de4c7e0be338ebec62c5e68554273c',
      //   thirdPartyPartnerCode: 'ttpush'
      // }
    }
  },
  watch: {
    ssoAppId: {
      handler(newVal) {
        if (newVal && !this.ssoInitialized) {
          this.init();
        }
      }
    }
  },
  mounted() {
    console.log(
      "[mounted] Login",
      "sso sdk:" + this.ssoSDK,
      this.ssoAppId
    );

    if (!window.qcsso) {
      const script = document.createElement("script");
      script.onload = this.onScriptLoaded;
      script.type = "text/javascript";
      script.src = this.ssoUrl;
      document.head.appendChild(script);
    } else {
      this.onScriptLoaded();
    }
  },
  methods: {
    onScriptLoaded(event = null) {
      if (event) {
        console.log("[login] qcsso was added");
      } else {
        console.log("[login] qcsso already existed");
      }

      // 有membership
      if (this.thirdPartyMember?.thirdPartyMemberId) {
        // get member ship 
        console.log('trying to get membership');
        fetchThirdPartyMemberInfo(this.thirdPartyMember?.thirdPartyPartnerCode, this.thirdPartyMember.thirdPartyMemberId)
        .then(res => {
          console.log('fetchThirdPartyMemberInfo done ' + JSON.stringify(res));
          this.$store.commit('updateThirdPartyMember', res.data);
        })
        .catch(e => {
          console.error('fetchThirdPartyMemberInfo failed: ', e);
          this.$store.commit('updateThirdPartyMember', null);
        })
      } else { // 無membership
        this.init();
      }
     
    },
    init() {
      console.log("[login] init qcsso:" + this.ssoAppId);
      const qcsso = window.qcsso;
      // const redirectUrl = window.location;
      let self = this;
      qcsso
        .init({
          // appId: this.ssoAppId || this.appId,
          appId: this.ssoAppId,
        })
        .then(() => {
          let loggedIn = qcsso.isLoggedIn();
          // self.$store.commit("updateIsLoggedIn", loggedIn);
          if (loggedIn) {
            console.log('[login] logged in');
            self.getUserProfile();
          } else {
            // stay home page 
            console.log('[login] not login ');
            self.$store.commit("updateIsLoggedIn", false);
            // qcsso.login({ redirectUrl });
          }
        })
        .catch((e) => {
          console.log("SSO init exception:", e);
          //self.$emit("error", this.$i18n.t("message.failedToLogin"));
        })
        .finally(()=>{
          self.$store.commit("updateSSOInitState", true);
          console.log('sso init done.', self.ssoInitialized);

        });

    },
    login() {
      console.log("[login] start login");
      const qcsso = window.qcsso;
      // 設定redirect url
      // 會根據是是有付款金額決定到哪一頁
      const redirectUrl = window.location.origin;
      console.log("login redirect url:" + redirectUrl);
      qcsso
        .init({
          appId: this.ssoAppId || this.appId,
        })
        .then(() => {
          if (qcsso.isLoggedIn()) {
            this.getUserProfile();
          } else {
            qcsso.login({ redirectUrl });
          }
        })
        .catch((e) => {
          console.log("[login] SSO init exception:", e);
          this.$notify({
            group: 'noti-popup',
            type: 'error',
            title: '登入',
            text: '登入失敗!'
          });
        });
    },
    getUserProfile() {
      console.log("[login] start get user profilie");
      try {
        const self = this;
        const qcsso = window.qcsso;
        // const token = qcsso.getAccessToken();
        qcsso
          .getProfile()
          .then(function (user) {
            console.log("[login] done get user profile", user);
            self.$store.commit("updateUser", user);
            self.$store.commit("updateIsLoggedIn", true);
            
            // 取得profile後，檢查用戶是否註冊會員，重導至不同頁面
            // {"userId":"KxewXRaZrPPRryQkbOgp","scope":"*","uuid":"d33c6808-ed2d-5c08-abbd-21f9c93c62b7"}
            // uuid --> 平台上此身份unique的id (userId 有可能重複，例如某兩種oauth service的id可能會撞到彼此，但uuid一定不重複)
            // userId --> 第三方真正的userId (可能會用於第三方的API，例如LINE的uid拿來推播之類的)
            // alert('登入成功');
          })
          .catch(function (e) {
            console.log(e);
            self.logout();
          })
          .finally(() => {});
      } catch (e) {
        this.isLoading = false;
      }
    },
    logout() {
      console.log("[login] start logout");
      const qcsso = window.qcsso;
      // let redirectUrl = window.location.protocol + "//" + window.location.host;
      // let location = window.location.href;
      const token = this.$route.params.token;
      const redirectUrl = window.location.origin + '/orders';
      const self = this;
       qcsso
        .init({
          appId: this.ssoAppId,
        })
        .then(() => {
          qcsso
          .logout({ logoutRedirectUri: redirectUrl, state: token })
          .then(() => {
            console.log("[login] logout succss");
            self.$store.commit("updateIsLoggedIn", false);
          })
          .catch((e) => {
            console.error(e);
          });
        })
      
    },
  },
};
</script>
